import { render, staticRenderFns } from "./switchdata.vue?vue&type=template&id=173a54b1&scoped=true&"
import script from "./switchdata.vue?vue&type=script&lang=js&"
export * from "./switchdata.vue?vue&type=script&lang=js&"
import style0 from "./switchdata.vue?vue&type=style&index=0&id=173a54b1&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "173a54b1",
  null
  
)

export default component.exports